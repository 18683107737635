import React, { useState } from "react";
import { ethers, BigNumber } from "ethers";
import roboPunksNFT from "../BabySmoleCoinSol.json";
import { Box, Button, Link, Flex, Input, Text , Image} from "@chakra-ui/react";
import MainLogo from "../assets/logo/babysmolecoin.png";
import Telegram from "../assets/social-media-icons/telegram.png";
import Twitter from "../assets/social-media-icons/x.png";
import Discord from "../assets/social-media-icons/discord.png";
import Solana from "../assets/logo/solana.svg";

const roboPunksNFTAddress = "sefser";

const MainMint = ({ accounts, setAccounts }) => {
  const [mintAmount, setMintAmount] = useState(1);
  const isConnected = Boolean(accounts[0]);

  const handleMint = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        roboPunksNFTAddress,
        roboPunksNFT.abi,
        signer
      );

      try {
        const response = await contract.mint(BigNumber.from(mintAmount));
        console.log(
          "🚀 ~ file: MainMint.jsx ~ line 23 ~ handleMint ~ response",
          response
        );
      } catch (error) {
        console.log(
          "🚀 ~ file: MainMint.jsx ~ line 30 ~ handleMint ~ error",
          error
        );
      }
    }
  };

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if (mintAmount >= 3) return;
    setMintAmount(mintAmount + 1);
  };

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
      <Box width="520px">
        <div>
        <Image src={MainLogo} boxSize="100px" margin="0 15px" />
          <Text fontSize="12px" textShadow="0 2px #000">
            Baby Smole
          </Text>
          <Text
            fontSize="15px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 2px 2px #000"
          >
            a baby smol mole and the smollest babysmolecoin in the smolana smolnet{" "}
          </Text>
        </div>

        
      
<p><font size="1" Shadow="0 5px #000">Send SOL to wallet:</font></p>

<p><Text fontSize="8px" textShadow="0 2px #000">4Nwh6pD9kpkyjDjU4Hf4DGoLFUapmMdJ32zvFQm9VR7N</Text></p>
<p><Text fontSize="8px" textShadow="0">You will receive $BMOLE airdrop ! Minimum 1 SOL !</Text></p>

        <Link href="https://t.me/eluverse_groups">
          <Image src={Telegram} boxSize="42px" margin="0 15px" />
        </Link>
        <Link href="https://twitter.com/0xBabysmolecoin">
          <Image src={Twitter} boxSize="42px" margin="0 15px" />
        </Link>
       <Link href="https://discord.gg/BQrad6YW">
          <Image src={Discord} boxSize="42px" margin="0 15px" />
        </Link>
      </Box>
    </Flex>
  );
};

export default MainMint;
