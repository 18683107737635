import React from "react";
import { Box, Button, Flex, Image, Text, Link, Spacer } from "@chakra-ui/react";


const NavBar = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);

  const connectAccount = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
    }
  };

  return (
    <Flex justify="space-between" align="center" padding="30px">
     
      <Flex justify="space-around" align="center" width="40%" padding="30px">
        <Box margin="0 15px"><Text fontSize="8px" textShadow="0">$BMOLE - Baby Smole Coin </Text></Box>
        <Spacer />

      </Flex>

      
      
        <Button
          backgroundColor="#d6517d"
          borderRadius="5px"
          boxShadow="0px 2px 2px 1px #0f0f0f"
          color="#fff"
          cursor="pointer"
          fontFamily="inherit"
          padding="15px"
          margin="0 15px"
        >
          Solana
        </Button>
      
    </Flex>
  );
};

export default NavBar;
